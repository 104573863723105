/* eslint-disable react/jsx-no-literals */
import React from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import {
  TAssetItemDetails,
  TBundleItemDetails,
  TOwnedItemInstance,
  TUserItemPermissions
} from '../constants/types';
import translationConfig from '../translation.config';
import { isLimited, isCollectible } from '../utils/itemDetailUtils';
import ItemDetailsContextMenu from './ItemDetailsContextMenu';
import { useCreatorReference } from '../utils/hooks';
import ShoppingCartButton from '../../shoppingCart/components/ShoppingCartButton';

type TItemDetailsInfoHeaderProps = {
  itemDetails: TAssetItemDetails | TBundleItemDetails;
  ownedItemInstances: Array<TOwnedItemInstance>;
  permissions: TUserItemPermissions;
};

export const ItemDetailsInfoHeader = ({
  itemDetails,
  ownedItemInstances,
  permissions,
  translate
}: TItemDetailsInfoHeaderProps & WithTranslationsProps): JSX.Element | null => {
  const { name } = itemDetails;

  const { CreatorLinkComponent } = useCreatorReference(itemDetails);

  return (
    <div className='item-details-info-header border-bottom item-name-container'>
      <div className='left'>
        <div className='item-details-name-row'>
          {!!itemDetails?.premiumPricing?.premiumPriceInRobux && (
            <span className='icon-premium-medium' />
          )}
          <h1>{name}</h1>
        </div>
        <div className='item-details-creator-container'>
          <span id='2sv-popup-container' />
          {!!CreatorLinkComponent && CreatorLinkComponent}
          {itemDetails.owned && (
            <span className='item-owned'>
              <div className='label-checkmark'>
                <span className='icon-checkmark-white-bold' />
              </div>
              {isLimited(itemDetails.itemRestrictions || []) ||
              isCollectible(itemDetails.itemRestrictions || []) ? (
                <span>
                  {translate('Label.ItemOwnedCount', {
                    itemCount: ownedItemInstances.length
                  })}
                </span>
              ) : (
                <span>{translate('Label.ItemOwned')}</span>
              )}
            </span>
          )}
        </div>
      </div>
      <div className='right'>
        <ItemDetailsContextMenu itemDetails={itemDetails} permissions={permissions} />
        <ShoppingCartButton />
      </div>
    </div>
  );
};
export default withTranslations(ItemDetailsInfoHeader, translationConfig);
