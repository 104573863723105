/* eslint-disable react/jsx-no-literals */
import React from 'react';
import * as itemPurchase from 'roblox-item-purchase';
import { TItemPricing, TItemPurchaseParams } from '../constants/types';
import { TItemDetailsInfoBodyProps } from './ItemDetailsInfoBody';
import { catalogTranslations, itemTranslations } from '../services/translationService';
import { hrefs } from '../constants/urlConfigs';

type TUnathentictedPurchaseButtonProps = TItemDetailsInfoBodyProps & {
  itemPricingInfo: TItemPricing;
};

type TPurchaseButtonProps = {
  itemPurchaseParams: TItemPurchaseParams | null;
};

const { createItemPurchase } = itemPurchase;
const [ItemPurchase, itemPurchaseService] = createItemPurchase();

export function UnathentictedPurchaseButton({
  itemPricingInfo,
  itemDetails
}: TUnathentictedPurchaseButtonProps): JSX.Element {
  if (itemPricingInfo.priceDisplayMode === 'UNAUTHENTICATED_USER_PREMIUM_ITEM') {
    return (
      <div className='btn-container'>
        <a
          className='btn-fixed-width-lg btn-primary-lg'
          href={hrefs.upgradeToPremium(itemDetails.id, itemDetails.itemType)}>
          {itemTranslations.actionGetPremium()}
        </a>
      </div>
    );
  }
  return (
    <div className='btn-container'>
      <a className='btn-growth-lg btn-fixed-width-lg' href={hrefs.loginWithRedirect()}>
        {itemTranslations.actionBuy()}
      </a>
    </div>
  );
}

export default function PurchaseButton({ itemPurchaseParams }: TPurchaseButtonProps): JSX.Element {
  return (
    // selenium tests use "PurchaseButton" class name to find the element
    // https://sourcegraph.rbx.com/github.rbx.com/Roblox/web-platform@1e48226ae6c032c21a88cc020639d2e7f1cf2075/-/blob/Assemblies/Selenium/Roblox.Selenium.Framework/Pages/Web/Item/ItemPage.cs?L72&subtree=true#tab=def
    <div className='btn-container'>
      <button
        className='shopping-cart-buy-button btn-growth-lg PurchaseButton'
        type='button'
        onClick={() => {
          itemPurchaseService.start();
        }}>
        {catalogTranslations.actionBuy()}
      </button>
      {!!itemPurchaseParams && <ItemPurchase {...itemPurchaseParams} />}
    </div>
  );
}
